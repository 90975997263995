import { useMemo } from 'react';
import CheckoutModel from '../../models/checkout/CheckoutModel';
import useCheckout from '../checkout/useCheckout';

const useCheckoutInfo = (id: string) => {
  const [{ data: checkoutD, fetching, error }] = useCheckout(id);
  const data = useMemo(() => new CheckoutModel(checkoutD?.node), [checkoutD]);

  return [{ data, fetching, error }];
};

export default useCheckoutInfo;
