import { useEffect } from 'react';
import { gql, useQuery } from 'urql';
import { Checkout } from '../../types/shopify/checkouts/Checkout';

const getCheckout = gql`
  query GetCheckout($id: ID!) {
    node(id: $id) {
      ... on Checkout {
        id
        webUrl
        completedAt
        createdAt
        customAttributes {
          key
          value
        }
        order {
          name
          orderNumber
        }
        lineItems(first: 10) {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    }
  }
`;

/**
 * [名前] チェックアウト
 * @param id チェックアウトID
 */
const useCheckout = (id: string) => {
  const [{ data, fetching, error }, refetch] = useQuery<{ node: Checkout }>({
    query: getCheckout,
    variables: { id },
    pause: !id,
  });

  // チェックアウトIDが変更されたら再フェッチ
  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  return [{ data, fetching, error }];
};

export default useCheckout;
