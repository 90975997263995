import initializeBasicAuth from 'nextjs-basic-auth';

const users = [
  {
    user: process.env.ENABLE_BASIC_USER || 'user',
    password: process.env.ENABLE_BASIC_PASSWORD || 'Password@1',
  },
];

export default initializeBasicAuth({ users });
