import {
  cacheExchange,
  Client,
  CombinedError,
  createClient,
  dedupExchange,
  fetchExchange,
  ssrExchange,
} from 'urql';
import { retryExchange } from '@urql/exchange-retry';
import { refocusExchange } from '@urql/exchange-refocus';

class HttpClient {
  protected client?: Client;

  protected createClient(userToken?: string) {
    if (this.client) {
      return this.client;
    }

    const isServerSide = typeof window === 'undefined';

    // The `ssrExchange` must be initialized with `isClient` and `initialState`
    const ssr = ssrExchange({
      isClient: !isServerSide,
    });

    const options = {
      initialDelayMs: 1000,
      maxDelayMs: 30000,
      randomDelay: false,
      maxNumberAttempts: 3,
      retryIf: (error: CombinedError) =>
        !!(error.graphQLErrors.length > 0 || error.networkError),
    };

    this.client = createClient({
      url: `https://${process.env.NEXT_PUBLIC_SHOPIFY_SHOP_DOMAIN}/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql.json`,
      fetchOptions: () => ({
        headers: {
          'X-Shopify-Storefront-Access-Token': userToken || '',
          'Content-Type': 'application/json, application/graphql',
        },
      }),
      exchanges: [
        dedupExchange,
        refocusExchange(),
        cacheExchange,
        retryExchange(options),
        fetchExchange,
        ssr,
      ],
    });

    return this.client;
  }
}

export default HttpClient;
