import React, { FC } from 'react';
import {
  AlertTemplateProps,
  AlertOptions,
  positions,
  transitions,
} from 'react-alert';
import { MdCancel } from 'react-icons/md';

const UplAlert: FC<AlertTemplateProps> = ({
  style,
  options,
  message,
  close,
}) => (
  <>
    {options.type === 'info' && (
      <div
        style={style}
        className='flex rounded border border-theme-200 bg-theme-100 py-3 pl-4 pr-2 text-theme shadow-xl sm:text-sm'
      >
        {message as React.ReactNode}
        <button
          onClick={close}
          className='rounded px-2 text-xl hover:text-theme-700'
        >
          <MdCancel />
        </button>
      </div>
    )}
    {options.type === 'success' && (
      <div
        style={style}
        className='flex rounded border border-green bg-green-100 py-3 pl-4 pr-2 text-green-700 shadow-xl sm:text-sm'
      >
        {message as React.ReactNode}
        <button
          onClick={close}
          className='rounded px-2 text-xl hover:text-green-200'
        >
          <MdCancel />
        </button>
      </div>
    )}
    {options.type === 'error' && (
      <div
        style={style}
        className='flex rounded border border-red bg-red-100 py-3 pl-4 pr-2 text-red-700 shadow-xl sm:text-sm'
      >
        {message as React.ReactNode}
        <button
          onClick={close}
          className='rounded px-2 text-xl hover:text-red-200'
        >
          <MdCancel />
        </button>
      </div>
    )}
  </>
);

export const options: AlertOptions = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '40px',
  transition: transitions.FADE,
};

export default UplAlert;
