import { FC } from 'react';

const UplSpinner: FC = () => (
  <>
    <div className='fixed left-0 top-0 z-10 size-full bg-black opacity-20' />
    <div className='fixed left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2'>
      <div
        className='size-20 animate-spin rounded-full border-8 border-theme'
        style={{ borderTopColor: 'transparent' }}
      />
    </div>
  </>
);

export default UplSpinner;
