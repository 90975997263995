import '../styles/globals.css';
import type { AppContext, AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { Provider } from 'urql';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from 'next/app';
import { Session } from 'next-auth';
import ShopifyFrontClient from '../modules/http/ShopifyFrontClient';
import UplAlert from '../components/atoms/UplAlert/UplAlert';
import UplAuthWrapper from '../components/layout/UplAuthWrapper/UplAuthWrapper';
import UplDrawerContextProvider from '../components/provider/UplDrawerContextProvider';
import basicAuth from '../modules/auth/basicAuth';

const client = new ShopifyFrontClient().getClient();
const queryClient = new QueryClient();

const reactAlertOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '10px',
  transition: transitions.FADE,
};

const MyApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps & Session) => (
  <SessionProvider session={session}>
    <QueryClientProvider client={queryClient}>
      <Provider value={client}>
        <AlertProvider template={UplAlert} {...reactAlertOptions}>
          <UplDrawerContextProvider>
            <UplAuthWrapper component={<Component {...pageProps} />} />
          </UplDrawerContextProvider>
        </AlertProvider>
      </Provider>
    </QueryClientProvider>
  </SessionProvider>
);

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { req, res } = appContext.ctx;
  if (req && res && process.env.ENABLE_BASIC_AUTH) {
    await basicAuth(req, res);
  }
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default MyApp;
