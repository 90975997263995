const MYPAGENAV = [
  { id: 1, label: 'お客様情報', link: '/mypage' },
  { id: 2, label: 'お届け先', link: '/mypage/address' },
];

export default MYPAGENAV;

export const PAYMENT_METHOD: { [key: string]: string } = {
  bill: '請求書払い',
  bank: '銀行振込',
  credit: 'クレジットカード払い',
};

export const PAYMENT_TYPE = {
  bill: '掛売会員',
  credit: 'クレジット会員',
  bank: '振込会員',
  all: 'ALL会員',
};

export const DELIVERYADDRESS = [
  {
    id: 1,
    name: '確認太郎',
    zip: '1234567',
    address: '東京都〇〇区〇〇町0-0-0番地',
    tel: '1122223333',
    company: '確認建設会社',
  },
  {
    id: 2,
    name: '確認太郎',
    zip: '1234567',
    address: '東京都〇〇区〇〇町0-0-0番地',
    tel: '1122223333',
    company: '確認建設会社',
  },
];

export const ORDERHISOTRY = [
  {
    id: 1,
    date: '2022/04/02',
    billing: '39ce38a0-02a5-496c-9a00ad7661487a0b',
    order: '39ce38a0-02a5-496c-9a00ad7661487a0b',
    products: [
      {
        id: 1,
        title: '高級マットレス',
        img: '/neji_1.jpg',
        maker: 'けんデポ',
      },
      {
        id: 2,
        title: '高級マットレス',
        img: '/neji_1.jpg',
        maker: 'けんデポ',
      },
    ],
  },
  {
    id: 2,
    date: '2022/04/02',
    billing: '39ce38a0-02a5-496c-9a00ad7661487a0b',
    order: '39ce38a0-02a5-496c-9a00ad7661487a0b',
    products: [
      {
        id: 1,
        title: '高級マットレス',
        img: '/neji_1.jpg',
        maker: 'けんデポ',
      },
    ],
  },
  {
    id: 3,
    date: '2022/04/01',
    billing: '39ce38a0-02a5-496c-9a00ad7661487a0b',
    order: '39ce38a0-02a5-496c-9a00ad7661487a0b',
    products: [
      {
        id: 1,
        title: '高級マットレス',
        img: '/neji_1.jpg',
        maker: 'けんデポ',
      },
      {
        id: 2,
        title: '高級マットレス',
        img: '/neji_1.jpg',
        maker: 'けんデポ',
      },
      {
        id: 3,
        title: '高級マットレス',
        img: '/neji_1.jpg',
        maker: 'けんデポ',
      },
    ],
  },
];
