import { Checkout } from '../../types/shopify/checkouts/Checkout';
import DateModel from '../estimate/DateModel';

const EXPIRATION_DATE = 20;
export default class CheckoutModel {
  private data?: Checkout;

  constructor(data?: Checkout) {
    this.data = data;
  }

  /**
   * チェックアウトが有効であるか
   */
  get isValid() {
    return new DateModel(String(this.data?.createdAt)).isWithinDays(
      EXPIRATION_DATE,
    );
  }

  /**
   * チェックアウトが完了したかどうか
   */
  get isCompleted() {
    return !!(this.data?.completedAt && this.data?.order?.name);
  }

  /**
   * クレジット支払い画面のURL
   */
  get webUrl() {
    return this.data?.webUrl;
  }

  /**
   * オーダー番号(#xxxx)
   */
  get orderName() {
    return this.data?.order?.name;
  }

  /**
   * 見積番号
   */
  get estimateNo() {
    return this.data?.customAttributes?.find(
      (attribute) => attribute.key === '見積番号',
    )?.value;
  }

  /**
   * ID
   */
  get id() {
    return this.data?.id;
  }

  /**
   * 一部抜粋したID
   */
  get matchedId() {
    return this.data?.id.match(/gid:\/\/shopify\/Checkout\/(?<id>.[^?]*)/)
      ?.groups?.id;
  }

  /**
   * クレジット決済のinformationページURL
   */
  get webInfomationUrl() {
    return `https://${process.env.NEXT_PUBLIC_SHOPIFY_SHOP_DOMAIN}/checkouts/co/${this.matchedId}/information`;
  }
}
