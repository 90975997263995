import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from 'react';

interface DrawerContextType {
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export const DrawerContext = createContext<DrawerContextType>({
  isDrawerOpen: false,
  setIsDrawerOpen: () => {},
});

interface Props {
  children: ReactNode;
}
const UplDrawerContextProvider: FC<Props> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const contextValue = useMemo(
    () => ({
      isDrawerOpen,
      setIsDrawerOpen,
    }),
    [isDrawerOpen],
  );

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  );
};
export default UplDrawerContextProvider;
