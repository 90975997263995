import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { gql, useMutation, useQuery } from 'urql';
import { CartLine } from '../../types/shopify/cart/CartLine.type';
import { Edges } from '../../types/shopify/Edges.type';
import useCheckoutInfo from '../registor/useCheckoutInfo';

const cartLinesRemove = `
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

const getCart = gql`
  query GetCart($id: ID!, $first: Int) {
    cart(id: $id) {
      lines(first: $first) {
        edges {
          cursor
          node {
            id
            quantity
          }
        }
      }
    }
  }
`;

const useAfterCheckout = () => {
  const router = useRouter();
  const { data: sessionData } = useSession();
  const alert = useAlert();
  // セッションがある場合、チェックアウトIDを取得
  const checkoutId =
    !sessionData || typeof window === 'undefined'
      ? ''
      : localStorage.getItem('CHECKOUT_ID') || '';

  // カートの中身を取得(カートIDまたチェックアウトIDがない場合は取得しない)
  const [{ data: cartD, fetching: cartF }] = useQuery<{
    cart: {
      lines: Edges<CartLine>;
    };
  }>({
    query: getCart,
    variables: { id: sessionData?.user.cart.id, first: 21 },
    pause: !sessionData?.user.cart.id || !checkoutId,
  });

  const [{ data: checkoutInfo, fetching: checkoutFetching }] =
    useCheckoutInfo(checkoutId);

  const [{ fetching: removeCartFetching }, removeCart] =
    useMutation(cartLinesRemove);

  // チェックアウト完了後の処理
  useEffect(() => {
    if (checkoutId && checkoutInfo.isCompleted && !checkoutFetching) {
      // 見積番号がない場合はカートを削除する
      if (!checkoutInfo.estimateNo) {
        removeCart({
          cartId: sessionData?.user.cart.id,
          lineIds: cartD?.cart.lines.edges.map(({ node }) => node.id),
        }).then(({ error, data }) => {
          if (error || data?.cartLinesRemove.userErrors.length !== 0) {
            alert.error('カート内の商品の削除に失敗しました');
          }
        });
      }
      // ローカルストレージのチェックアウトIDを削除
      localStorage.removeItem('CHECKOUT_ID');
    }
  }, [
    alert,
    cartD,
    checkoutFetching,
    checkoutId,
    checkoutInfo,
    removeCart,
    router,
    sessionData,
  ]);

  return [{ fetching: checkoutFetching || removeCartFetching || cartF }];
};

export default useAfterCheckout;
