import moment from 'moment';
import 'moment/locale/ja';

export default class DateModel {
  public originalValue?: string;

  private moment?: moment.Moment;

  constructor(date?: string) {
    this.originalValue = date;
    this.moment = date ? moment(date) : undefined;
  }

  /**
   * 日付と時刻を取得する
   */
  get formattedDateTime() {
    return this.moment ? this.moment.locale('ja').format('LLL') : '';
  }

  /**
   * 日付を取得する
   */
  get formattedDate() {
    return this.moment ? this.moment.locale('ja').format('YYYY年M月D日') : '';
  }

  /**
   * 日付を取得する
   */
  get unFormattedDate() {
    return this.moment ? this.moment.locale('ja').format('YYYYMMDD') : '';
  }

  get effectiveDate() {
    return this.moment
      ? this.moment?.add(30, 'd').locale('ja').format('YYYY年M月D日')
      : '';
  }

  isWithinDays(days: number) {
    const diff = moment().diff(this.moment, 'days');
    return diff <= days;
  }

  isEstimateWithin30Days() {
    const currentDate = moment().startOf('day'); // 現在の日付の開始時点（午前0時）
    const targetDate = moment(this.moment).startOf('day'); // 比較対象の日付の開始時点（午前0時）
    const diff = currentDate.diff(targetDate, 'days');
    return diff <= 30;
  }

  isAfter(date: string) {
    return this.moment?.isAfter(new DateModel(date).moment);
  }
}
